// import { useState } from 'react';
import styled from 'styled-components';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import DatePicker, { DateRangeInput } from 'components/inputs/DatePicker';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';

const ActionsWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 10px;
  gap: 10px;
`;

const GoSupportFilterBar = () => {
  // const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const {
    dashboardState: { daterange, searchText },
    setSelectedDateRange,
    setSearchText,
  } = useDashboardControls();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    // if (timeoutId) {
    //   clearTimeout(timeoutId);
    // }

    // const newTimeoutId = setTimeout(() => {
    setSearchText(value);
    // }, 300);

    // setTimeoutId(newTimeoutId);
  };

  return (
    <ActionsWrapper>
      <Actions>
        <LabelWrap label="Datum">
          <DatePicker
            calendarStartDay={1}
            customInput={
              <DateRangeInput daterange={[daterange[0], daterange[1]]} />
            }
            selectsRange
            startDate={daterange[0]}
            endDate={daterange[1]}
            dateFormat="yyyy-MM-dd"
            locale="sv"
            onChange={setSelectedDateRange}
            populateHolidays
            shouldCloseOnSelect
            showYearDropdown
            showMonthDropdown
          />
        </LabelWrap>
        {/* <LabelWrap label="Enheter">
          <BusinessUnitSelect
            onChange={(value) => {
              console.log(value);
              setSelectedBusinessUnits(new Set(value));
            }}
            value={selectedBusinessUnits}
          />
        </LabelWrap> */}
      </Actions>
      <Actions>
        <Input
          // disabled // Until search is implemented
          onChange={handleSearchChange}
          placeholder="Sök"
          value={searchText ?? ''}
          width={200}
        />
      </Actions>
    </ActionsWrapper>
  );
};

export default GoSupportFilterBar;
