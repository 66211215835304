import { useCallback, useMemo, useState } from 'react';
import {
  AssignmentDeviationEnum,
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  CaseTypeEnum,
  ExpectedExpenseDto,
  ExpenseType,
  IAssignmentModel,
  StandardizedCommentDto,
  UserModel,
  ZipCodeArea,
} from 'api';
import { toInputDateTimeString } from 'utils/date-helpers';
import {
  BasicFormState,
  BasicFormValidators,
  ValidationResults,
} from 'hooks/useForm';
import { caseTypeSendsCustomerUpdateMessages } from '../CaseAssignments/utils';
import { isExcemptFromEstimationValidation } from 'constants/AppConstants';

export enum AssignmentField {
  assignmentDeviationID,
  assignmentCancelTypeID,
  assignmentTypeID,
  assignmentStatusID,
  sendCustomerUpdateMessages,

  fromName,
  fromAddress,
  fromCity,
  fromZip,
  fromCounty,

  toName,
  toAddress,
  toCity,
  toZip,
  toCounty,

  bookedTo,
  startTime,
  endTime,
  fixedAssignmentTimeInHours,
  breakTime,
  autoApprove,
  extraInfo,

  assignedTo,
  assignedToRating,
  assignedToRatingReason,

  estimatedDuration,
  estimatedStartTime,
  systemEstimatedDurationExact,
  systemEstimatedDurationApprox,

  expectedExpenses,

  standardizedComments,

  allowPrivateStopOver,

  hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime,

  standByAssignmentTimeInHours,
}

export const hourInMilliseconds = 1000 * 60 * 60;

export function getDefaultAssignment(): AssignmentModel {
  return AssignmentModel.fromJS({
    caseID: -1,
    assignmentID: -1,
    assignmentDeviationID: AssignmentDeviationEnum.None,
    assignmentcancelTypeID: undefined,
    assignmentTypeID: AssignmentTypeEnum.Normal,
    sendCustomerUpdateMessages: true,
    timestamp: '',
    assignedTo: undefined,
    assignmentStatusID: AssignmentStatusEnum.Created,
    fromName: '',
    fromAddress: '',
    fromCity: '',
    fromZip: '',
    fromCounty: undefined,
    toName: '',
    toAddress: '',
    toCity: '',
    toZip: '',
    toCounty: undefined,
    bookedTo: new Date(),
    startTime: undefined,
    endTime: undefined,
    fixedAssignmentTime: undefined,
    breakTime: 0,
    autoApprove: false,
    extraInfo: '',
    inspectionApproved: undefined,
    assignmentTimeDisplay: '',
    workedTimeDisplay: '',
    expenseSum: 0,
    circleKExpenseSum: 0,
    estimatedDuration: undefined,
    estimatedStartTime: undefined,
    systemEstimatedDurationExact: undefined,
    systemEstimatedDurationApprox: undefined,
    expectedExpenses: [],
    standardizedComments: [],
    allowPrivateStopOver: false,
    hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime: false,
    assignedToRating: undefined,
    assignedToRatingReason: undefined,
    standByAssignmentTime: undefined,
  });
}

export function initializeAssignmentForm(
  assignment: IAssignmentModel | null,
  defaultAssignmentType?: AssignmentTypeEnum
): BasicFormState<AssignmentField> {
  if (assignment) {
    return {
      [AssignmentField.assignmentDeviationID]: String(
        assignment.assignmentDeviationID ?? AssignmentDeviationEnum.None
      ),
      [AssignmentField.assignmentCancelTypeID]: String(
        assignment.assignmentCancelTypeID ?? ''
      ),
      [AssignmentField.assignmentTypeID]: String(assignment.assignmentTypeID),
      [AssignmentField.assignmentStatusID]: String(
        assignment.assignmentStatusID
      ),
      [AssignmentField.sendCustomerUpdateMessages]:
        assignment.sendCustomerUpdateMessages ? 'true' : 'false',

      [AssignmentField.fromName]: assignment.fromName,
      [AssignmentField.fromAddress]: assignment.fromAddress,
      [AssignmentField.fromCity]: assignment.fromCity,
      [AssignmentField.fromZip]: assignment.fromZip,
      [AssignmentField.fromCounty]: assignment.fromCounty?.id.toString() ?? '',

      [AssignmentField.toName]: assignment.toName,
      [AssignmentField.toAddress]: assignment.toAddress,
      [AssignmentField.toCity]: assignment.toCity,
      [AssignmentField.toZip]: assignment.toZip,
      [AssignmentField.toCounty]: assignment.toCounty?.id.toString() ?? '',

      [AssignmentField.bookedTo]: toInputDateTimeString(assignment.bookedTo),
      [AssignmentField.startTime]: assignment.startTime
        ? toInputDateTimeString(assignment.startTime)
        : '',
      [AssignmentField.endTime]: assignment.endTime
        ? toInputDateTimeString(assignment.endTime)
        : '',
      [AssignmentField.fixedAssignmentTimeInHours]:
        assignment.fixedAssignmentTime !== undefined &&
        assignment.fixedAssignmentTime !== null
          ? (
              Math.round(
                assignment.fixedAssignmentTime / (hourInMilliseconds / 100)
              ) / 100
            ).toString()
          : '',
      [AssignmentField.breakTime]: String(assignment.breakTime),
      [AssignmentField.autoApprove]: assignment.autoApprove ? 'true' : 'false',
      [AssignmentField.extraInfo]: assignment.extraInfo,

      [AssignmentField.assignedTo]: String(assignment.assignedTo?.userID ?? ''),

      [AssignmentField.estimatedDuration]:
        assignment.estimatedDuration !== undefined
          ? String(assignment.estimatedDuration)
          : '',
      [AssignmentField.estimatedStartTime]: assignment.estimatedStartTime
        ? toInputDateTimeString(assignment.estimatedStartTime)
        : '',
      [AssignmentField.systemEstimatedDurationExact]:
        assignment.systemEstimatedDurationExact !== undefined
          ? String(assignment.systemEstimatedDurationExact)
          : '',
      [AssignmentField.systemEstimatedDurationApprox]:
        assignment.systemEstimatedDurationApprox !== undefined
          ? String(assignment.systemEstimatedDurationApprox)
          : '',

      [AssignmentField.expectedExpenses]: assignment.expectedExpenses.reduce(
        (acc, cur) => {
          if (acc === '') {
            return `${Number(cur.type)}`;
          }
          return `${acc},${Number(cur.type)}`;
        },
        ''
      ),

      [AssignmentField.standardizedComments]: JSON.stringify(
        assignment.standardizedComments ?? []
      ),

      [AssignmentField.allowPrivateStopOver]: assignment.allowPrivateStopOver
        ? 'true'
        : 'false',

      [AssignmentField.hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime]:
        'false',

      [AssignmentField.assignedToRating]:
        assignment.assignedToRating !== undefined
          ? assignment.assignedToRating?.toString()
          : '',
      [AssignmentField.assignedToRatingReason]:
        assignment.assignedToRatingReason !== undefined
          ? assignment.assignedToRatingReason
          : '',
      [AssignmentField.standByAssignmentTimeInHours]:
        assignment.standByAssignmentTime !== undefined &&
        assignment.standByAssignmentTime !== null
          ? (
              Math.round(
                assignment.standByAssignmentTime / (hourInMilliseconds / 100)
              ) / 100
            ).toString()
          : '',
    };
  }

  return {
    [AssignmentField.assignmentDeviationID]: String(
      AssignmentDeviationEnum.None
    ),
    [AssignmentField.assignmentCancelTypeID]: '',
    [AssignmentField.assignmentTypeID]: String(
      defaultAssignmentType ?? AssignmentTypeEnum.Normal
    ),
    [AssignmentField.assignmentStatusID]: String(AssignmentStatusEnum.Created),
    [AssignmentField.sendCustomerUpdateMessages]: 'true',

    [AssignmentField.fromName]: '',
    [AssignmentField.fromAddress]: '',
    [AssignmentField.fromCity]: '',
    [AssignmentField.fromZip]: '',
    [AssignmentField.fromCounty]: '',

    [AssignmentField.toName]: '',
    [AssignmentField.toAddress]: '',
    [AssignmentField.toCity]: '',
    [AssignmentField.toZip]: '',
    [AssignmentField.toCounty]: '',

    [AssignmentField.bookedTo]: '',
    [AssignmentField.startTime]: '',
    [AssignmentField.endTime]: '',
    [AssignmentField.fixedAssignmentTimeInHours]: '',
    [AssignmentField.breakTime]: '',
    [AssignmentField.autoApprove]: 'false',
    [AssignmentField.extraInfo]: '',

    [AssignmentField.assignedTo]: '',

    [AssignmentField.estimatedDuration]: '',
    [AssignmentField.estimatedStartTime]: '',
    [AssignmentField.systemEstimatedDurationExact]: '',
    [AssignmentField.systemEstimatedDurationApprox]: '',

    [AssignmentField.expectedExpenses]: '', // `${Number(ExpenseType.Gas)}`,

    [AssignmentField.standardizedComments]: '[]',

    [AssignmentField.allowPrivateStopOver]: 'false',
    [AssignmentField.hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime]:
      'false',
    [AssignmentField.assignedToRating]: '',
    [AssignmentField.assignedToRatingReason]: '',
    [AssignmentField.standByAssignmentTimeInHours]: '',
  };
}

export function assignmentFormToAssignment(
  base: IAssignmentModel | undefined,
  assignmentForm: BasicFormState<AssignmentField>,
  usersById: {
    [key: string]: UserModel;
  },
  zipCodeAreadById: {
    [id: string]: ZipCodeArea;
  },
  caseType: CaseTypeEnum
): AssignmentModel {
  const assignedToId = assignmentForm[AssignmentField.assignedTo];

  const parsed = JSON.parse(
    assignmentForm[AssignmentField.standardizedComments] ?? []
  );

  const standardizedComments = parsed.reduce(
    (acc: StandardizedCommentDto[], cur: StandardizedCommentDto) => {
      return [
        ...acc,
        new StandardizedCommentDto({
          comment: cur.comment,
          created: new Date(cur.created),
        }),
      ];
    },
    [] as StandardizedCommentDto[]
  );

  return new AssignmentModel({
    caseID: base?.caseID || -1,
    assignmentID: base?.assignmentID || -1,
    assignmentDeviationID: Number(
      assignmentForm[AssignmentField.assignmentDeviationID]
    ),
    assignmentCancelTypeID:
      assignmentForm[AssignmentField.assignmentCancelTypeID] === ''
        ? undefined
        : Number(assignmentForm[AssignmentField.assignmentCancelTypeID]),
    assignmentTypeID: Number(assignmentForm[AssignmentField.assignmentTypeID]),
    sendCustomerUpdateMessages:
      caseTypeSendsCustomerUpdateMessages(caseType) &&
      assignmentForm[AssignmentField.sendCustomerUpdateMessages] === 'true',
    timestamp: base?.timestamp || '',
    assignedTo: usersById[assignedToId],

    assignmentStatusID: Number(
      assignmentForm[AssignmentField.assignmentStatusID]
    ),

    fromName: assignmentForm[AssignmentField.fromName],
    fromAddress: assignmentForm[AssignmentField.fromAddress],
    fromCity: assignmentForm[AssignmentField.fromCity],
    fromZip: assignmentForm[AssignmentField.fromZip],
    fromCounty:
      zipCodeAreadById[assignmentForm[AssignmentField.fromCounty]] ?? undefined,

    toName: assignmentForm[AssignmentField.toName],
    toAddress: assignmentForm[AssignmentField.toAddress],
    toCity: assignmentForm[AssignmentField.toCity],
    toZip: assignmentForm[AssignmentField.toZip],
    toCounty:
      zipCodeAreadById[assignmentForm[AssignmentField.toCounty]] ?? undefined,

    bookedTo: new Date(assignmentForm[AssignmentField.bookedTo]),
    startTime: assignmentForm[AssignmentField.startTime]
      ? new Date(assignmentForm[AssignmentField.startTime])
      : undefined,
    endTime: assignmentForm[AssignmentField.endTime]
      ? new Date(assignmentForm[AssignmentField.endTime])
      : undefined,
    fixedAssignmentTime:
      assignmentForm[AssignmentField.fixedAssignmentTimeInHours] === ''
        ? undefined
        : Math.floor(
            Number(assignmentForm[AssignmentField.fixedAssignmentTimeInHours]) *
              hourInMilliseconds
          ),
    standByAssignmentTime:
      assignmentForm[AssignmentField.standByAssignmentTimeInHours] === ''
        ? undefined
        : Math.floor(
            Number(
              assignmentForm[AssignmentField.standByAssignmentTimeInHours]
            ) * hourInMilliseconds
          ),
    breakTime: Number(assignmentForm[AssignmentField.breakTime]),
    autoApprove: assignmentForm[AssignmentField.autoApprove] === 'true',
    extraInfo: assignmentForm[AssignmentField.extraInfo],
    inspectionApproved: base?.inspectionApproved ?? undefined,

    assignmentTimeDisplay: base?.assignmentTimeDisplay ?? '',
    workedTimeDisplay: base?.workedTimeDisplay ?? '',
    expenseSum: base?.expenseSum ?? 0,

    estimatedDuration:
      Math.floor(Number(assignmentForm[AssignmentField.estimatedDuration])) ??
      undefined,
    estimatedStartTime:
      assignmentForm[AssignmentField.estimatedStartTime] !== undefined &&
      assignmentForm[AssignmentField.estimatedStartTime].length > 0
        ? new Date(assignmentForm[AssignmentField.estimatedStartTime])
        : undefined,
    systemEstimatedDurationExact:
      Math.floor(
        Number(assignmentForm[AssignmentField.systemEstimatedDurationExact])
      ) ?? undefined,
    systemEstimatedDurationApprox:
      Math.floor(
        Number(assignmentForm[AssignmentField.systemEstimatedDurationApprox])
      ) ?? undefined,

    expectedExpenses: assignmentForm[AssignmentField.expectedExpenses]
      .split(',')
      .reduce((acc, cur) => {
        if (cur === '') {
          return acc;
        }
        const type = Number(cur) as ExpenseType;
        return [
          ...acc,
          new ExpectedExpenseDto({
            assignmentId: -1,
            type,
            estimatedCost: 0,
          }),
        ];
      }, [] as ExpectedExpenseDto[]),

    standardizedComments,

    allowPrivateStopOver:
      assignmentForm[AssignmentField.allowPrivateStopOver] === 'true',

    assignedToRating:
      assignmentForm[AssignmentField.assignedToRating] !== ''
        ? Number(assignmentForm[AssignmentField.assignedToRating])
        : undefined,

    assignedToRatingReason:
      assignmentForm[AssignmentField.assignedToRatingReason] !== ''
        ? assignmentForm[AssignmentField.assignedToRatingReason]
        : undefined,
    assignmentRequests: base?.assignmentRequests ?? [],
    circleKExpenseSum: base?.circleKExpenseSum ?? 0,
    drivingPolicyAssignmentInNeedOfHandling:
      base?.drivingPolicyAssignmentInNeedOfHandling ?? false,
  });
}

const validators: BasicFormValidators<AssignmentField> = {
  [AssignmentField.fromAddress]: (v) =>
    v.trim().length === 0 ? { error: 'Ange från, adress' } : undefined,
  [AssignmentField.fromZip]: (v) =>
    v.trim().length === 0 ? { error: 'Ange från, postkod' } : undefined,
  [AssignmentField.fromCity]: (v) =>
    v.trim().length === 0 ? { error: 'Ange från, stad' } : undefined,

  [AssignmentField.toAddress]: (v) =>
    v.trim().length === 0 ? { error: 'Ange till, adress' } : undefined,
  [AssignmentField.toZip]: (v) =>
    v.trim().length === 0 ? { error: 'Ange till, postkod' } : undefined,
  [AssignmentField.toCity]: (v) =>
    v.trim().length === 0 ? { error: 'Ange till, stad' } : undefined,

  [AssignmentField.bookedTo]: (v) =>
    v.trim().length === 0 ? { error: 'Ange Bokad till' } : undefined,

  [AssignmentField.estimatedDuration]: (value, inputName, formState) => {
    // only validate if assignee is set
    if (formState[AssignmentField.assignedTo] === '') {
      return undefined;
    }
    // if this assignment has sibling purchase assignments at same booked to and address with estimated start time and duration, don't validate
    if (
      formState[
        AssignmentField
          .hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime
      ] === 'true'
    ) {
      return undefined;
    }

    // if start and end times are set, but equate to 0 duration, then don't validate
    if (
      formState[AssignmentField.startTime] !== undefined &&
      formState[AssignmentField.endTime] !== undefined &&
      formState[AssignmentField.startTime] !== '' &&
      formState[AssignmentField.endTime] !== ''
    ) {
      const startTime = new Date(formState[AssignmentField.startTime]);
      const endTime = new Date(formState[AssignmentField.endTime]);
      if (startTime.getTime() === endTime.getTime()) {
        return undefined;
      }
    }

    if (
      isExcemptFromEstimationValidation(
        Number(formState[AssignmentField.assignedTo])
      )
    ) {
      return undefined;
    }

    if (
      Number(formState[AssignmentField.assignmentStatusID]) ===
      AssignmentStatusEnum.Complete
    ) {
      return undefined;
    }

    if (formState[AssignmentField.fixedAssignmentTimeInHours]) {
      return undefined;
    }

    // return false if the value is not a number or is less than 0
    return !(Number(value) > 0)
      ? { error: 'Ange estimerad uppdragstid' }
      : undefined;
  },
  [AssignmentField.estimatedStartTime]: (value, inputName, formState) => {
    // only validate if assignee is set
    if (formState[AssignmentField.assignedTo] === '') {
      return undefined;
    }
    // if this assignment has sibling purchase assignments at same booked to and address with estimated start time and duration, don't validate
    if (
      formState[
        AssignmentField
          .hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime
      ] === 'true'
    ) {
      return undefined;
    }
    if (
      isExcemptFromEstimationValidation(
        Number(formState[AssignmentField.assignedTo])
      )
    ) {
      return undefined; // these users don't need to set estimated start time
    }
    if (formState[AssignmentField.startTime] !== '') {
      return undefined; // no need for estimated start time if actual start time is set
    }
    // require if estimated duration is set
    if (
      formState[AssignmentField.estimatedDuration] === undefined ||
      formState[AssignmentField.estimatedDuration] === ''
    ) {
      return undefined;
    }
    return !formState[AssignmentField.estimatedStartTime]
      ? { error: 'Ange estimerad starttid' }
      : undefined;
  },
};

export function createValidations(
  assignmentFormState: BasicFormState<AssignmentField>
) {
  const assignmentValidations: ValidationResults<AssignmentField> = {};

  // do validations
  if (
    assignmentFormState[AssignmentField.assignmentStatusID] ===
    AssignmentStatusEnum.Discarded.toString()
  ) {
    return {}; // don't validate removed assignments
  }
  Object.entries(validators).forEach(([fieldNameStr, validator]) => {
    if (validator && fieldNameStr in assignmentFormState) {
      const fieldName = Number(fieldNameStr) as AssignmentField;

      const v = validator(
        assignmentFormState[fieldName],
        fieldName,
        assignmentFormState
      );
      if (v) assignmentValidations[fieldName] = v;
    }
  });

  return assignmentValidations;
}

interface CaseAssignmentFormState {
  [assignmentId: string]: BasicFormState<AssignmentField>;
}
interface CaseAssignmentFormValidations {
  [assignmentId: string]: ValidationResults<AssignmentField>;
}
interface CaseAssignmentForm {
  state: CaseAssignmentFormState;
  validations: CaseAssignmentFormValidations;
  onChange(assignmentId: number, field: AssignmentField, value: string): void;
  /** if null, start creating new asignment */
  startEdit(
    assignment: AssignmentModel | null,
    defaultAssignmentType?: AssignmentTypeEnum
  ): void;
  cancelEdit(assignmentId: number): void;
  cancelAllEdits(): void;
}

const useCaseAssignmentForms = (): CaseAssignmentForm => {
  const [state, setState] = useState<{
    fieldState: CaseAssignmentFormState;
    validations: CaseAssignmentFormValidations;
  }>({
    fieldState: {},
    validations: {},
  });

  const handleChange = useCallback(
    (assignmentId: number, field: AssignmentField, value: string) => {
      setState((s) => {
        const newAssignmentState: BasicFormState<AssignmentField> = {
          ...s.fieldState[assignmentId],
          [field]: value,
        };

        return {
          ...s,
          fieldState: {
            ...s.fieldState,
            [assignmentId]: newAssignmentState,
          },
          validations: {
            ...s.validations,
            [assignmentId]: createValidations(newAssignmentState),
          },
        };
      });
    },
    []
  );

  const handleStartEdit = useCallback(
    (
      assignment: AssignmentModel | null,
      defaultAssignmentType?: AssignmentTypeEnum
    ) => {
      const initialAssignmentFormState = initializeAssignmentForm(
        assignment,
        defaultAssignmentType
      );

      setState((s) => ({
        ...s,
        fieldState: {
          ...s.fieldState,
          [assignment?.assignmentID ?? -1]: initialAssignmentFormState,
        },
        validations: {
          ...s.validations,
          [assignment?.assignmentID ?? -1]: createValidations(
            initialAssignmentFormState
          ),
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCancelEdit = useCallback((assignmentId: number) => {
    setState((s) => {
      const newState: typeof state = {
        ...s,
        fieldState: { ...s.fieldState },
        validations: { ...s.validations },
      };
      delete newState.fieldState[assignmentId];
      delete newState.validations[assignmentId];
      return newState;
    });
  }, []);

  const handleCancelAllEdits = useCallback(() => {
    setState((s) => ({ ...s, fieldState: {}, validations: {} }));
  }, []);

  return useMemo(
    (): CaseAssignmentForm => ({
      state: state.fieldState,
      validations: state.validations,
      onChange: handleChange,
      startEdit: handleStartEdit,
      cancelEdit: handleCancelEdit,
      cancelAllEdits: handleCancelAllEdits,
    }),
    [
      state,
      handleCancelAllEdits,
      handleCancelEdit,
      handleChange,
      handleStartEdit,
    ]
  );
};

export default useCaseAssignmentForms;
