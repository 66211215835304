/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  CaseEventDTO,
  GoSupportGetUnhandledFaultyCarsParams,
  GoSupportGetUnreadCommentsParams,
} from '.././model';
import useGoSupportGetUnreadCommentsMutator from '.././mutator/custom-instance';
import useGoSupportGetUnhandledFaultyCarsMutator from '.././mutator/custom-instance';

export const useGoSupportGetUnreadCommentsHook = () => {
  const goSupportGetUnreadComments =
    useGoSupportGetUnreadCommentsMutator<CaseEventDTO[]>();

  return (params?: GoSupportGetUnreadCommentsParams, signal?: AbortSignal) => {
    return goSupportGetUnreadComments({
      url: `/api/GoSupport/GetUnreadComments`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnreadCommentsQueryKey = (
  params?: GoSupportGetUnreadCommentsParams
) => {
  return [
    `/api/GoSupport/GetUnreadComments`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnreadCommentsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnreadCommentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGoSupportGetUnreadCommentsQueryKey(params);

  const goSupportGetUnreadComments = useGoSupportGetUnreadCommentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>
  > = ({ signal }) => goSupportGetUnreadComments(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnreadCommentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>
>;
export type GoSupportGetUnreadCommentsQueryError = unknown;

export const useGoSupportGetUnreadComments = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnreadCommentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnreadCommentsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetUnhandledFaultyCarsHook = () => {
  const goSupportGetUnhandledFaultyCars =
    useGoSupportGetUnhandledFaultyCarsMutator<CaseEventDTO[]>();

  return (
    params?: GoSupportGetUnhandledFaultyCarsParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetUnhandledFaultyCars({
      url: `/api/GoSupport/GetUnhandledFaultyCars`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnhandledFaultyCarsQueryKey = (
  params?: GoSupportGetUnhandledFaultyCarsParams
) => {
  return [
    `/api/GoSupport/GetUnhandledFaultyCars`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnhandledFaultyCarsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledFaultyCarsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetUnhandledFaultyCarsQueryKey(params);

  const goSupportGetUnhandledFaultyCars =
    useGoSupportGetUnhandledFaultyCarsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
    >
  > = ({ signal }) => goSupportGetUnhandledFaultyCars(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnhandledFaultyCarsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>>
>;
export type GoSupportGetUnhandledFaultyCarsQueryError = unknown;

export const useGoSupportGetUnhandledFaultyCars = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledFaultyCarsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnhandledFaultyCarsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
