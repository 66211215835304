import {
  faChevronDown,
  faChevronUp,
  faCog,
  faCompressAlt,
  faExpandAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/Dropdown';
import Button from 'components/inputs/Button';
import Checkbox from 'components/inputs/Checkbox';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import useOutsideClick from 'hooks/useOutsideClick';
import { useRef, useState } from 'react';
import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 10px;
  gap: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

const ViewActionsBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    dashboardState: { collapsed, expanded, widgets },
    setCollapsedState,
    setExpandedState,
    setWidgets,
  } = useDashboardControls();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(dropdownRef, () => {
    if (isOpen) setIsOpen(false);
  });
  return (
    <Actions>
      <Dropdown
        content={
          isOpen && (
            <ContentWrapper ref={dropdownRef}>
              <h3>Visa</h3>
              <Checkbox
                checked={Object.values(widgets).every((w) => w.active)}
                onChange={(e) => {
                  setWidgets(
                    widgets.map((w) => ({
                      ...w,
                      active: e.currentTarget.checked,
                    }))
                  );
                }}
              >
                Alla
              </Checkbox>
              {widgets
                .filter((c) => c.identifier !== undefined)
                .map((widgetMeta, i) => (
                  <Checkbox
                    key={i}
                    checked={widgetMeta.active}
                    onChange={(e) => {
                      setWidgets(
                        widgets.map((w) =>
                          w.identifier === widgetMeta.identifier
                            ? { ...w, active: e.currentTarget.checked }
                            : w
                        )
                      );
                    }}
                  >
                    {widgetMeta.title ?? widgetMeta.title}
                  </Checkbox>
                ))}
            </ContentWrapper>
          )
        }
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((p) => !p);
          }}
          icon={<FontAwesomeIcon icon={faCog} />}
          iconPlacement="right"
        >
          Fönster
        </Button>
      </Dropdown>
      <Button onClick={() => setCollapsedState(!collapsed)}>
        {collapsed ? 'Fäll ut' : 'Fäll in'}
        <FontAwesomeIcon
          icon={collapsed ? faChevronDown : faChevronUp}
          style={{ marginLeft: '5px' }}
        />
      </Button>
      <Button onClick={() => setExpandedState(!expanded)}>
        {expanded ? 'Minimera' : 'Maximera'}
        <FontAwesomeIcon
          color="white"
          icon={expanded ? faCompressAlt : faExpandAlt}
          style={{ marginLeft: '10px' }}
        />
      </Button>
    </Actions>
  );
};

export default ViewActionsBar;
