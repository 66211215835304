import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import {
  getGoSupportGetUnreadCommentsQueryKey,
  useGoSupportGetUnreadComments,
} from 'api/go-support/go-support';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/inputs/Checkbox';
import styled, { css } from 'styled-components';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import { useQueryClient } from '@tanstack/react-query';
import { CaseEventDTO } from 'api/model';

const commentIdRowClassName = (caseEventId: number): string =>
  `comment-row-${caseEventId}`;

const SavingRowAnimationWrapper = styled.div<{ savingAssignmentIds: number[] }>`
  display: contents;

  ${({ savingAssignmentIds }) =>
    savingAssignmentIds.map(
      (id) => css`
        .${commentIdRowClassName(id)} {
          animation: assignmentlist-save-animation 1s infinite;
        }
      `
    )}

  @keyframes assignmentlist-save-animation {
    0% {
      background: #ddeefd;
    }
    50% {
      background: ${({ theme }) => theme.colors.background.primary};
    }
    100% {
      background: #ddeefd;
    }
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CommentsWidget = () => {
  const [comments, setComments] = useState<CaseEventDTO[]>([]);
  const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, searchText },
  } = useDashboardControls();

  const queryClient = useQueryClient();
  const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
    useCaseMarkEventsAsReadByAdmin({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            `${getGoSupportGetUnreadCommentsQueryKey()}`,
          ]);
        },
      },
    });

  const getComments = useGoSupportGetUnreadComments({
    startDate: daterange[0] ?? undefined,
    endDate: daterange[1] ?? undefined,
  });

  useEffect(() => {
    if (getComments.data) {
      setComments(getComments.data);
    } else {
      setComments([] as CaseEventDTO[]);
    }
  }, [getComments.data]);

  const filteredComments = useMemo(() => {
    if (!searchText.length) {
      return comments;
    }
    return comments
      ? comments.filter((c) => {
          return (
            c.comment?.toLowerCase().includes(searchText.toLowerCase()) ||
            c.registrationNumber
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (users[c.createdByID]?.name ?? '')
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        })
      : [];
  }, [comments, searchText, users]);

  const columnSettings: ColumnSetting<CaseEventDTO, any>[] = useMemo(() => {
    const columns: ColumnSetting<CaseEventDTO, any>[] = [
      {
        head: 'Nr',
        render: (e, t, f, i) => i + 1,
        width: 15,
      },
      {
        head: 'Reg.nr',
        render: (row) => `${row.registrationNumber}`,
        width: 50,
        sortFunction: (a, b) => {
          return a.registrationNumber.localeCompare(b.registrationNumber);
        },
      },
      {
        head: 'Tid',
        render: (row) => `${formatDateTime(row.created)}`,
        width: 100,
        sortFunction: (a, b) => a.created.getTime() - b.created.getTime(),
      },
      {
        head: 'Användare',
        render: (row) => {
          return (
            <span>
              {users[row.createdByID] ? users[row.createdByID].name : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.createdByID]?.name ?? '').localeCompare(
            users[b.createdByID]?.name ?? ''
          ),
      },
      {
        head: 'Kommentar',
        render: (row) => row.comment,
        width: 300,
        sortFunction: (a, b) => a.comment.localeCompare(b.comment),
      },
      {
        head: ' ',
        render: (row) => (
          <Centered>
            <FontAwesomeIcon
              onClick={() => {
                window.open(
                  `${Routes.search.index}/${row.caseID}`,
                  row.caseID.toString() ?? '_blank'
                );
              }}
              icon={faArrowUpRightFromSquare}
              fontSize="18px"
              style={{ cursor: 'pointer' }}
            />
          </Centered>
        ),
        width: 20,
      },
      {
        head: 'Klart',
        render: (row) => (
          <Centered>
            <Checkbox
              disabled={markAsReadStatus === 'loading'}
              onChange={async () => {
                setOngoingSaves([...ongoingSaves, row.id]);
                await markEventAsRead({ data: [row.id] });
                setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
              }}
            />
          </Centered>
        ),
        width: 30,
      },
    ];
    return columns;
  }, [markAsReadStatus, markEventAsRead, ongoingSaves, users]);
  return (
    <Widget
      title="Kommentarer"
      count={comments.length}
      isLoading={getComments.isLoading}
      info="Markera som klar för att markera kommentar som läst."
    >
      <SavingRowAnimationWrapper savingAssignmentIds={ongoingSaves}>
        <Table
          columnSettings={columnSettings}
          rows={filteredComments}
          rowClassName={(row) => commentIdRowClassName(row.id)}
          useColumnWidthAsFlexAmount
        />
      </SavingRowAnimationWrapper>
    </Widget>
  );
};

export default CommentsWidget;
