import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { WarningInfo } from 'utils/assignment-warnings';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  .warning-icons {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .warning-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
  }
`;

const WarningTextContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  z-index: 100;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  text-align: left;

  .warning-icon {
    color: red;
    margin-right: 5px;
  }

  .warning-text {
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 20px 1fr;
  }
`;

// red background, white text
const NumberBadge = styled.div`
  position: absolute;
  right: -50%;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  warnings: WarningInfo[];
};

// Shows a list of warning icons, which expands to the right on hover to show the warning texts
const ExpandableWarningsWidget: React.FC<Props> = ({ warnings }) => {
  const [expanded, setExpanded] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  useEffect(() => {
    if (expanded && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setPosition({ x: rect.right, y: rect.top });
    }
  }, [expanded]);

  const getIcon = useCallback((warning: WarningInfo) => {
    return warning.icon ?? <FontAwesomeIcon icon={faWarning} />;
  }, []);

  return (
    <Wrapper
      ref={wrapperRef}
      className="expandable-warnings-widget"
      onMouseEnter={toggleExpanded}
      onMouseLeave={toggleExpanded}
    >
      <div className="warning-icons">
        {
          // if warnings is longer than 1, show a warning icon with the number of warnings
          warnings.length > 1 ? (
            <div className="warning-icon">
              <FontAwesomeIcon icon={faWarning} />
              <NumberBadge>{warnings.length}</NumberBadge>
            </div>
          ) : (
            // otherwise, show the warning icon
            warnings.map((warning, index) => (
              <div key={index} className="warning-icon">
                {getIcon(warning)}
              </div>
            ))
          )
        }
      </div>
      {expanded &&
        createPortal(
          <WarningTextContainer
            style={{ position: 'fixed', top: position.y, left: position.x }}
          >
            {warnings.map((warning, index) => (
              <div key={index} className="warning-text">
                <span className="warning-icon">{getIcon(warning)}</span>
                <span>
                  {warning.warning}{' '}
                  {warning.details && <span>({warning.details})</span>}
                </span>
              </div>
            ))}
          </WarningTextContainer>,
          document.body
        )}
    </Wrapper>
  );
};

export default ExpandableWarningsWidget;
