import {
  faCancel,
  faExternalLink,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseClient, CaseEventType, CaseExtendedModel, CaseModel } from 'api';
import KeyValueList from 'components/KeyValueList';
import Modal from 'components/Modal';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ApiResponse, useApiCall } from 'swaggerhooks/lib';
import { formatDateTime } from 'utils/date-helpers';
import { arraySpreadIf } from 'utils/spreading';
import { useDetailedCaseContext } from '../DetailedCaseProvider';
import { CaseTypeEnum } from 'api/model';
import AuthorizedTextButton from 'components/inputs/AuthorizedTextButton';
import Roles from 'constants/Roles';
import assignmentWarnings from 'utils/assignment-warnings';

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const RightTextButton = styled(AuthorizedTextButton)`
  margin-left: auto;
  text-align: right;
`;

const Warning = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.background.negative};
  `}
`;

interface UnmarkAsInvoicedModalProps {
  caseResponse: ApiResponse<CaseExtendedModel | undefined>;
}
const UnmarkAsInvoicedModal: FC<UnmarkAsInvoicedModalProps> = ({
  caseResponse,
}) => {
  const modalStack = useModalStack();

  const updateAssignmentCall = useApiCall(
    CaseClient,
    (c, caseModel: CaseModel) =>
      c.updateCase(
        caseModel,
        CaseEventType.UnmarkedAsInvoiced,
        'Avmarkerade ärendet som fakturerat'
      )
  );

  return (
    <Modal
      buttons={[
        {
          label: 'Kör på!',
          onClick: async () => {
            if (caseResponse.response?.case) {
              const updatedCase = CaseModel.fromJS({
                ...caseResponse.response.case,
                invoiceDataID: null,
              });

              const [, error] = await updateAssignmentCall.run(updatedCase);
              if (!error) {
                modalStack.pop();
                caseResponse.update();
              }
            }
          },
        },
        {
          icon: <FontAwesomeIcon icon={faCancel} />,
          label: 'Avbryt',
          onClick: () => modalStack.pop(),
        },
      ]}
      title="Avmarkera som fakturerat"
    >
      Ärendet kommer att kunna ingå i nästa fakturaunderlag, men det kommer
      fortfarande finnas kvar i den redan genererade Excel-exporten från sin
      tidigare fakturering. Är du säker på att du vill fortsätta?
    </Modal>
  );
};

interface Props {
  caseResponse: CaseExtendedModel;
  className?: string;
}

const CaseDataViewer: FC<Props> = ({ caseResponse, className }) => {
  const { caseTypes, caseStatuses, tyreTypes } = useTranslations();
  const { caseResponse: response } = useDetailedCaseContext();
  const modalStack = useModalStack();

  const handleRemoveInvoiceDataIdClick = () => {
    modalStack.push(<UnmarkAsInvoicedModal caseResponse={response} />);
  };

  const {
    case: {
      caseTypeID,
      caseStatusID,
      closedDate,
      registrationNumber,
      vehicleGearBox,
      vehicleMileage,
      vehicleYearModel,
      customerName,
      customerPhoneNumber,
      bookerName,
      bookerPhoneNumber,
      // timestamp,
      company,
      externalTestUrl,
      externalProtocolUrl,
      // assignmentList,
      // hasLinkedCases,
      // rideOrderID,
      // createdByID,
      // createdBy,
      // modifiedByID,
      // modifiedBy,
      isElectric,
      isPremiumCar,
      isTrailer,
      isTruck,
      sendCustomerSurvey,
      vehicleBrand,
      vehicleValuation,
      vehicleType,
    },
  } = caseResponse;

  const getFlags = useCallback(() => {
    const arr: string[] = [];
    if (isElectric) arr.push('Elbil');
    if (isPremiumCar) arr.push('Premiumbil');
    if (isTrailer) arr.push('Släp');
    if (isTruck) arr.push('Lastbil');

    return arr.toString();
  }, [isElectric, isPremiumCar, isTrailer, isTruck]);

  return (
    <Wrapper className={className}>
      <Row>
        <KeyValueList
          colonKey
          rows={[
            { key: 'Typ', value: caseTypes[caseTypeID] },
            { key: 'Status', value: caseStatuses[caseStatusID] },
            ...arraySpreadIf(!!closedDate, {
              key: 'Stängdes',
              value: closedDate ? formatDateTime(closedDate) : '-',
            }),
            { key: 'Företag', value: company?.name },
            {
              key: 'Bokare',
              value: bookerName,
            },
            {
              key: 'Bokare tel',
              value: bookerPhoneNumber,
            },
          ]}
        />
        <KeyValueList
          colonKey
          rows={[
            { key: 'Kund', value: customerName },
            { key: 'Kund tel', value: customerPhoneNumber },
            {
              key: 'Regnr',
              value: (
                <>
                  {registrationNumber} {vehicleGearBox ? ' M' : ' A'}
                </>
              ),
            },
            { key: 'Miltal', value: vehicleMileage },
            { key: 'Årsmodell', value: vehicleYearModel },
            { key: 'Flaggor', value: getFlags() },
          ]}
        />
        <KeyValueList
          colonKey
          rows={[
            {
              key: 'Testprotokoll',
              value:
                externalTestUrl !== '' ? (
                  <StyledLink
                    href={externalTestUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Öppna extern länk <FontAwesomeIcon icon={faExternalLink} />
                  </StyledLink>
                ) : (
                  'Saknas'
                ),
            },
            {
              key: 'Inspektionsprotokoll',
              value:
                externalProtocolUrl !== '' ? (
                  <StyledLink
                    href={externalProtocolUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Öppna extern länk <FontAwesomeIcon icon={faExternalLink} />
                  </StyledLink>
                ) : (
                  'Saknas'
                ),
            },
            ...arraySpreadIf(
              (caseResponse.case.vehicleTyreType !== undefined &&
                caseResponse.case.caseTypeID ===
                  CaseTypeEnum.InternalDelivery) ||
                caseResponse.case.caseTypeID === CaseTypeEnum.Extra,
              {
                key: 'Däcktyp',
                value: tyreTypes[caseResponse.case.vehicleTyreType!],
              }
            ),
            ...arraySpreadIf(!!sendCustomerSurvey, {
              key: 'Skicka kundundersökning',
              value: sendCustomerSurvey ? 'Ja' : 'Nej',
            }),
            {
              key: 'Märke',
              value: vehicleBrand,
            },
            {
              key: 'Typ',
              value: vehicleType,
            },
            {
              key: 'Värdering',
              value: vehicleValuation,
            },
          ]}
        />
        {Number.isInteger(caseResponse.case.invoiceDataID) && (
          <RightTextButton
            onClick={handleRemoveInvoiceDataIdClick}
            roles={[Roles.Ekonomi]}
          >
            Avmarkera som fakturerat
          </RightTextButton>
        )}
      </Row>
      {assignmentWarnings.getCaseWarnings(caseResponse.case).map((warning) => (
        <Warning key={warning.warning}>
          {warning.icon ?? <FontAwesomeIcon icon={faWarning} />}{' '}
          {warning.warning}
        </Warning>
      ))}
    </Wrapper>
  );
};

export default CaseDataViewer;
