import React, { useMemo } from 'react';
import MultiSelect, { Option } from './MultiSelect';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits';

interface Props {
  value: Set<number>;
  onChange: (value: Set<number>) => void;
}
const BusinessUnitSelect: React.FC<Props> = ({ onChange, value }) => {
  const { unitsById } = useBusinessUnits();
  const businessUnitOptions = useMemo((): Option<number>[] => {
    return Array.from(unitsById.values()).map((unit) => ({
      label: unit.name,
      value: unit.id,
    }));
  }, [unitsById]);

  return (
    <MultiSelect
      onChange={(units) => {
        onChange(units as Set<number>);
      }}
      options={businessUnitOptions}
      placeholder="Välj enhet"
      value={value}
      disabled // Until filter is implemented
    />
  );
};

export default BusinessUnitSelect;
