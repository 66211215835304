import CommentsWidget from './widgets/CommentsWidget';
// import FaultyCarWidget from './widgets/FaultyCarWidget';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import DashboardWidgetsWrapper from 'components/Dashboard/WidgetWrapper';
import { GoSupportWidgetsEnum } from './utils';
import { memo } from 'react';
import { WidgetConfig } from 'components/Dashboard/types';

const widgetConfigGoSupport: WidgetConfig<GoSupportWidgetsEnum>[] = [
  // {
  //   widgetType: GoSupportWidgetsEnum.FaultyCar,
  //   component: memo(FaultyCarWidget),
  // },
  {
    widgetType: GoSupportWidgetsEnum.Comments,
    component: memo(CommentsWidget),
  },
];

const Dashboard = () => {
  const {
    dashboardState: { widgets },
  } = useDashboardControls();

  const getIsActive = (widgetType: GoSupportWidgetsEnum) => {
    return widgets.some(
      (w) => w.identifier === widgetType && w.active === true
    );
  };

  return (
    <DashboardWidgetsWrapper>
      {widgetConfigGoSupport.map(
        ({ widgetType, component: Component }) =>
          getIsActive(widgetType) && <Component key={widgetType} />
      )}
    </DashboardWidgetsWrapper>
  );
};

export default Dashboard;
