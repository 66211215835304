export enum GoSupportWidgetsEnum {
  Comments,
  // FaultyCar,
}

export const DefaultGoSupportWidgets = [
  // {
  //   identifier: GoSupportWidgetsEnum.FaultyCar,
  //   title: 'Fel på bil',
  //   active: true,
  // },
  {
    identifier: GoSupportWidgetsEnum.Comments,
    title: 'Kommentarer',
    active: true,
  },
];
