import AuthorizedTextButton from 'components/inputs/AuthorizedTextButton';
import KeyValueList from 'components/KeyValueList';
import useTranslations from 'contexts/basicData/useTranslations';
import {
  formatDateTime,
  formatTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import {
  AssignmentCancelTypeEnum,
  AssignmentDeviationEnum,
  AssignmentModel,
  AssignmentStatusEnum,
  CaseExtendedModel,
  CaseModel,
  CaseStatusEnum,
  CaseTypeEnum,
  PredefinedAction,
} from 'api';
import NewExpenseModal from '../Expenses/NewExpenseModal';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { arraySpreadIf } from 'utils/spreading';
import { caseTypeSendsCustomerUpdateMessages } from './utils';
import FormattedAddress from 'components/FormattedAddress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCancel,
  faClock,
  faClone,
  faExternalLink,
  faRunning,
  faTrashAlt,
  faUserAlt,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import PredefinedActionModal from 'components/PredefinedActions/PredefinedActionModal';
import StandardizedComments from 'components/StandardizedComments/StandardizedComments';
import { generatePath, useNavigate } from 'react-router-dom';
import useModalStack from 'contexts/modal/useModalStack';
import { useCopyCaseState } from 'contexts/basicData/useCopyCase';
import { getDefaultAssignment } from '../DetailedCaseFormProvider/useCaseAssignmentForms';
import { getRouteForCaseType } from 'hooks/useCurrentRouteCaseType';
import CreateCaseModal from 'components/CreateCaseModal';
import useResultingStartAndEndTime from './useResultingStartAndEndTimes';
import { useDetailedCaseContext } from '../DetailedCaseProvider';
import { useAssignmentMarkAssignmentInNeedOfHandlingAsHandled } from 'api/assignment/assignment';
import { AssignmentRequestStatusEnum, AssignmentViewModel } from 'api/model';
import TextButton from 'components/inputs/TextButton';
import useMe from 'contexts/authentication/useMe';
import Roles, { includesRoles } from 'constants/Roles';
import Routes from 'constants/Routes';
import { useCaseGetAssignmentOverlaps } from 'api/case/case';
import assignmentWarnings from 'utils/assignment-warnings';
import styled, { css } from 'styled-components';
import TooltipInfoWarning from 'components/TooltipInfoWarning';
import useAssignmentWarningsAndMultiplePurchases from 'hooks/useAssignmentWarningsAndMultiplePurchases';
import { useUserGetDrivingPolicyReportForUnPlannedAssignment } from 'api/user/user';
import { userNamesExcemptFromEstimationValidation } from 'constants/AppConstants';
import CaseAssignmentAvailabilityWidget from './CaseAssignmentAvailabilityWidget';
import WorkHoursSpan from './WorkHoursSpan';
import ReturnAssignmentFinder from './ReturnAssignmentFinder';

const Wrapper = styled.div<{ assignmentStatus: AssignmentStatusEnum }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0;

  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
  opacity: ${({ assignmentStatus }) =>
    assignmentStatus === AssignmentStatusEnum.Discarded ? 0.5 : 1};
`;

const StyledKVList = styled(KeyValueList)`
  b {
    font-weight: 600;
  }
`;

const Actions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  min-width: 100px;
  padding-left: 10px;
  position: relative;
`;

const EllipsisWrapper = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.background.button};
  padding: 5px;
`;

const BadgeSpan = styled.span<{ statusId: AssignmentStatusEnum }>`
  padding: 1px;
  border-radius: 3px;
  background-color: ${({ theme, statusId }) =>
    statusId === AssignmentStatusEnum.Approved
      ? theme.colors.background.positive
      : theme.colors.background.primary};
  color: ${({ theme, statusId }) =>
    statusId === AssignmentStatusEnum.Approved
      ? theme.colors.foreground.button
      : theme.colors.foreground.primary};
`;

const Warning = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.background.negative};
  `}
`;

const nonCancelableAssignmentStatuses: Set<AssignmentStatusEnum> = new Set([
  AssignmentStatusEnum.Canceled,
  AssignmentStatusEnum.Aborted,
  AssignmentStatusEnum.Approved,
  AssignmentStatusEnum.Discarded,
  AssignmentStatusEnum.CanceledNotFinished,
  AssignmentStatusEnum.AbortedNotFinished,
]);

const nonStopOverableAssignmentStatuses: Set<AssignmentStatusEnum> = new Set([
  AssignmentStatusEnum.Created,
  AssignmentStatusEnum.Planned,
  AssignmentStatusEnum.Assigned,
  AssignmentStatusEnum.Accepted,
  AssignmentStatusEnum.Complete,
  AssignmentStatusEnum.Approved,
  AssignmentStatusEnum.Canceled,
  AssignmentStatusEnum.Aborted,
  AssignmentStatusEnum.Discarded,
]);

const approvableAssignmentStatuses: Set<AssignmentStatusEnum> = new Set([
  AssignmentStatusEnum.Complete,
  AssignmentStatusEnum.Aborted,
  AssignmentStatusEnum.Canceled,
]);

interface CaseAssignmentProps {
  assignment: AssignmentModel;
  assignmentCase: CaseExtendedModel;
  currentCaseType: CaseTypeEnum;
  editMode: boolean;
  onEditClick(assignment: AssignmentModel): void;
  onCancelClick(assignmentId: number): void;
  onStopOverClick(assignmentId: number): void;
  onDiscardClick(assignmendId: number): void;
  onApproveClick(assignmentId: number): void;
  showMarkedAsHandled?: boolean;
}

const CaseAssignment: React.FC<CaseAssignmentProps> = ({
  assignment,
  assignmentCase,
  currentCaseType,
  editMode,
  onEditClick,
  onCancelClick,
  onStopOverClick,
  onDiscardClick,
  onApproveClick,
  showMarkedAsHandled,
}) => {
  const me = useMe();
  const navigate = useNavigate();
  const modalStack = useModalStack();
  const getOverlapsCall = useCaseGetAssignmentOverlaps();
  const { caseResponse } = useDetailedCaseContext();

  const [overlaps, setOverlaps] = useState<AssignmentViewModel[]>([]);

  useEffect(() => {
    const getCaseOverlaps = async () => {
      const updatedAssignment = assignment as AssignmentModel;
      const result = await getOverlapsCall.mutateAsync({
        data: updatedAssignment,
      });

      setOverlaps(result ?? []);
    };
    getCaseOverlaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignment.assignmentID,
    assignment.startTime,
    assignment.endTime,
    assignment.estimatedStartTime,
    assignment.estimatedDuration,
  ]);

  const [, setCaseCopy] = useCopyCaseState();
  const [showNewExpenseModal, setShowNewExpenseModal] = useState(false);
  const [showPredefinedActions, setShowPredefinedActions] = useState(false);
  const [predefinedActionType, setPredefinedActionType] =
    useState<PredefinedAction>();
  const {
    assignmentStatuses,
    assignmentTypes,
    assignmentDeviations,
    expenseTypes,
    predefinedActions,
    cancelTypes,
    assignmentRequestStatuses,
  } = useTranslations();

  const {
    assignmentID,
    assignmentDeviationID,
    assignmentCancelTypeID,
    assignmentTypeID,
    sendCustomerUpdateMessages,
    autoApprove,
    assignmentStatusID,

    fromName,
    fromAddress,
    fromCity,
    fromZip,
    fromCounty,

    toName,
    toAddress,
    toCity,
    toZip,
    toCounty,

    bookedTo,
    startTime,
    endTime,
    breakTime,
    assignmentTimeDisplay,
    fixedAssignmentTime,
    workedTimeDisplay,
    assignedTo,
    extraInfo,

    // followUpAssignment,

    estimatedDuration,
    estimatedStartTime,

    expectedExpenses,
    assignedToRating,
    assignedToRatingReason,

    drivingPolicyAssignmentInNeedOfHandling,

    standByAssignmentTime,
  } = assignment;

  const {
    multiplePurchasesList,
    sameTimePurchaseWithEstimatedDuration,
    isViolatingDrivingPolicy,
  } = useAssignmentWarningsAndMultiplePurchases(
    currentCaseType,
    assignment,
    true
  );

  const { startAndEndTimeString: sameTimePurchaseStartAndEndTimeString } =
    useResultingStartAndEndTime({
      bookedTo: assignment.bookedTo,
      caseType: currentCaseType,
      assignmentType:
        sameTimePurchaseWithEstimatedDuration?.assignment.assignmentTypeID,
      estimatedDuration:
        sameTimePurchaseWithEstimatedDuration?.assignment.estimatedDuration,
      estimatedStartTime:
        sameTimePurchaseWithEstimatedDuration?.assignment.estimatedStartTime,
    });

  const markAsHandled = useAssignmentMarkAssignmentInNeedOfHandlingAsHandled();

  const onMarkAsHandled = async () => {
    if (!markAsHandled.isLoading) {
      await markAsHandled.mutateAsync({
        params: { assignmentId: assignment.assignmentID },
      });
    }
  };

  const handleCopyCaseAssignment = useCallback(() => {
    const caseCopy = CaseModel.fromJS({
      ...structuredClone(assignmentCase.case),
    });

    if (caseCopy) {
      // replace id with -1 to indicate that it is a new case
      caseCopy.caseID = -1;
      // set case status to open
      caseCopy.caseStatusID = CaseStatusEnum.Open;
      caseCopy.closedDate = undefined;
      // remove ride order id
      caseCopy.rideOrderID = undefined;
      // remove invoice data id
      caseCopy.invoiceDataID = undefined;

      caseCopy.assignmentList = caseCopy.assignmentList
        .filter((a) => a.assignmentID === assignmentID) // only the current assignment
        .map(
          (a) =>
            ({
              ...getDefaultAssignment(),
              // replace ids with -1 to indicate that it is a new assignment
              assignmentID: -1,
              caseID: -1,
              // replace assignment status & deviation with default values
              assignmentStatusID: AssignmentStatusEnum.Created,
              assignmentDeviationID: AssignmentDeviationEnum.None,
              // values to copy
              assignmentTypeID: a.assignmentTypeID,
              autoApprove: a.autoApprove,
              bookedTo: a.bookedTo,
              breakTime: 0,
              estimatedDuration: a.estimatedDuration,
              systemEstimatedDurationApprox: a.systemEstimatedDurationApprox,
              systemEstimatedDurationExact: a.systemEstimatedDurationExact,
              // extraInfo: a.extraInfo, <-- This should not be passed on as it is not relevant for the new assignment
              expectedExpenses: a.expectedExpenses,
              fromName: a.fromName,
              fromAddress: a.fromAddress,
              fromCity: a.fromCity,
              fromZip: a.fromZip,
              fromCounty: a.fromCounty,
              toName: a.toName,
              sendCustomerUpdateMessages: a.sendCustomerUpdateMessages,
              toAddress: a.toAddress,
              toCity: a.toCity,
              toZip: a.toZip,
              toCounty: a.toCounty,
              fixedAssignmentTime: a.fixedAssignmentTime,
              allowPrivateStopOver: a.allowPrivateStopOver,
            }) as AssignmentModel
        );

      setCaseCopy(caseCopy);

      const caseTypeRoute = getRouteForCaseType(caseCopy.caseTypeID);
      navigate(generatePath(caseTypeRoute, { id: -1 }));
      modalStack.push(
        <CreateCaseModal
          caseType={caseCopy.caseTypeID}
          onCaseCreated={(copyCaseId: number) => {
            const newpath = `${caseTypeRoute}/${copyCaseId}`;
            navigate(newpath);
            modalStack.pop();
          }}
          onClose={() => modalStack.pop()}
        />
      );
    } else {
      setCaseCopy(null);
    }
  }, [assignmentCase, assignmentID, modalStack, navigate, setCaseCopy]);

  useEffect(() => {
    if (showNewExpenseModal) {
      setPredefinedActionType(undefined);
    }
  }, [showNewExpenseModal]);

  useEffect(() => {
    if (predefinedActionType) {
      setShowNewExpenseModal(false);
    }
  }, [predefinedActionType]);

  const isAssignmentApprovable = (
    _assignmentStatusID: AssignmentStatusEnum,
    _hasEndTime: boolean
  ) => {
    if (
      // Approvable statuses are always approvable
      approvableAssignmentStatuses.has(_assignmentStatusID) &&
      _hasEndTime
    ) {
      return true;
    }
    return false;
  };

  const { startAndEndTimeString } = useResultingStartAndEndTime({
    bookedTo,
    estimatedDuration,
    estimatedStartTime,
    caseType: currentCaseType,
    assignmentType: assignmentTypeID,
  });

  const derivedDuration = useMemo(() => {
    if (startTime && endTime) {
      return new Date(endTime).getTime() - new Date(startTime).getTime();
    }

    return estimatedDuration ?? 0;
  }, [startTime, endTime, estimatedDuration]);

  const drivingPolicyReportResult =
    useUserGetDrivingPolicyReportForUnPlannedAssignment(
      {
        userId: assignment.assignedTo?.userID,
        bookedTo: assignment.bookedTo,
        startOrEstimatedStartTime:
          assignment.startTime ?? assignment.estimatedStartTime,
        endOrEstimatedEndTime: assignment.endTime,
        estimatedDuration: derivedDuration,
        excludeAssignmentId: assignment.assignmentID,
      },
      {
        query: {
          enabled:
            !!assignment.assignedTo &&
            !userNamesExcemptFromEstimationValidation.includes(
              assignment.assignedTo.name
            ),
        },
      }
    );

  const getDurationString = (am: AssignmentModel) => {
    const start = am.startTime ?? am.estimatedStartTime;
    let end = am.endTime;
    if (!end && am.estimatedDuration && startTime) {
      end = new Date(startTime.getTime() + am.estimatedDuration);
    }
    if (!end) {
      return '';
    }
    return `(${formatTime(start)} - ${formatTime(end)})`;
  };
  const warnings = useMemo(() => {
    return assignmentWarnings.getAssignmentWarnings(assignment);
  }, [assignment]);

  const rows = [
    ...arraySpreadIf(warnings.length > 0, {
      key: 'Varningar',
      value: (
        <div>
          {warnings.map((w) => (
            <Warning key={w.warning}>
              {w.icon ?? <FontAwesomeIcon icon={faWarning} />} {w.warning}
            </Warning>
          ))}
        </div>
      ),
    }),
    ...arraySpreadIf(overlaps.length > 0, {
      key: 'Överlappande uppdrag',
      value: (
        <div>
          {overlaps.map((o) => (
            <TextButton
              key={o.assignment.assignmentID}
              onClick={() => navigate(`/sok/${o.case.caseID}`)}
              title={`Överlappar med uppdrag ${o.case.registrationNumber} - ${o.assignment.assignmentID}`}
              style={{ color: 'red' }}
            >
              {`${o.case.registrationNumber} ${o.assignment.assignmentID} ${getDurationString(o.assignment as AssignmentModel)}`}{' '}
              <FontAwesomeIcon icon={faExternalLink} />
            </TextButton>
          ))}
        </div>
      ),
    }),
    ...arraySpreadIf(
      includesRoles(me?.roles ?? [], [Roles.Annons]) &&
        assignment.assignmentRequests.length > 0,
      {
        key: 'Annonsförfrågan',
        value: (
          <>
            <TextButton
              style={{
                paddingLeft: 0,
                color: (
                  [
                    AssignmentRequestStatusEnum.Denied,
                    AssignmentRequestStatusEnum.Removed,
                  ] as AssignmentRequestStatusEnum[]
                ).includes(
                  assignment.assignmentRequests[0]?.assignmentRequestStatusID
                )
                  ? 'red'
                  : undefined,
                textDecoration: (
                  [
                    AssignmentRequestStatusEnum.Denied,
                    AssignmentRequestStatusEnum.Removed,
                  ] as AssignmentRequestStatusEnum[]
                ).includes(
                  assignment.assignmentRequests[0]?.assignmentRequestStatusID
                )
                  ? 'line-through'
                  : undefined,
              }}
              onClick={() =>
                window.open(
                  `${Routes.internalDeliveryGroup.requests.index}/${assignment.assignmentRequests[0]?.requestID}`,
                  `annonsforfragan${assignment.assignmentRequests[0]?.requestID}`
                )
              }
              title="Annonsförfrågan"
            >
              {assignment.assignmentRequests[0]?.targetStartTime
                ? `${formatDateTime(
                    assignment.assignmentRequests[0]?.targetStartTime
                  )}`
                : ''}{' '}
              <FontAwesomeIcon icon={faExternalLink} />
            </TextButton>
            <span>
              (
              {
                assignmentRequestStatuses[
                  assignment.assignmentRequests[0]?.assignmentRequestStatusID
                ]
              }
              )
            </span>
          </>
        ),
      }
    ),
    { key: 'Uppdrags-ID', value: assignmentID === -1 ? '-' : assignmentID },
    ...arraySpreadIf(
      assignment.assignmentStatusID === AssignmentStatusEnum.Approved &&
        assignment.assignmentDeviationID === AssignmentDeviationEnum.None &&
        includesRoles(me?.roles ?? [], [Roles.GoSupport, Roles.SuperAdmin]),
      {
        key: 'Fälttestarutvärdering',
        value: assignedToRating ?? '-',
      }
    ),
    ...arraySpreadIf(
      assignment.assignmentStatusID === AssignmentStatusEnum.Approved &&
        assignment.assignmentDeviationID === AssignmentDeviationEnum.None &&
        includesRoles(me?.roles ?? [], [Roles.GoSupport, Roles.SuperAdmin]),
      {
        key: 'Fälttestarutvärdering anledning',
        value: assignedToRatingReason ?? '-',
      }
    ),
    ...arraySpreadIf(multiplePurchasesList.length > 0, {
      key: 'Flerbilsinköp',
      value: (
        <>
          {multiplePurchasesList.map((ass) => (
            <TextButton
              key={ass.assignment.assignmentID}
              onClick={() =>
                window.open(
                  `/sok/${ass.case.caseID}`,
                  ass.case.caseID.toString() ?? '_blank'
                )
              }
              title={`Flerbilsinköp med ${ass.case.registrationNumber}`}
            >
              {ass.case.registrationNumber}{' '}
              {!!ass.assignment.estimatedDuration &&
                ass.assignment.assignedTo?.userID ===
                  assignment.assignedTo?.userID && (
                  <FontAwesomeIcon icon={faClock} />
                )}
              {ass.assignment.assignedTo?.userID ===
                assignment.assignedTo?.userID && (
                <FontAwesomeIcon
                  title={ass.assignment.assignedTo?.name}
                  icon={faUserAlt}
                />
              )}{' '}
              <FontAwesomeIcon icon={faExternalLink} />
            </TextButton>
          ))}
        </>
      ),
    }),
    {
      key: 'Bokad till',
      value: (
        <span>
          <span>{bookedTo ? formatDateTime(bookedTo) : '-'}</span>
        </span>
      ),
    },
    { key: 'Typ', value: assignmentTypes[assignmentTypeID] },
    {
      key: 'Status',
      value: (
        <BadgeSpan statusId={assignmentStatusID}>
          {assignmentStatuses[assignmentStatusID]}
        </BadgeSpan>
      ),
    },
    ...arraySpreadIf(
      assignmentDeviationID !== AssignmentDeviationEnum.None &&
        assignmentStatusID === AssignmentStatusEnum.Approved,
      {
        key: 'Avvikelse',
        value: assignmentDeviations[assignmentDeviationID],
      }
    ),
    {
      key: 'Tillåt privat mellanlandning',
      value: assignment.allowPrivateStopOver ? 'Ja' : 'Nej',
    },
    ...arraySpreadIf(
      assignmentCancelTypeID !== null && !Number.isNaN(assignmentCancelTypeID),
      {
        key: 'Avbokningstyp',
        value: cancelTypes[assignmentCancelTypeID as AssignmentCancelTypeEnum],
      }
    ),
    ...arraySpreadIf(caseTypeSendsCustomerUpdateMessages(currentCaseType), {
      key: 'Skicka SMS',
      value: sendCustomerUpdateMessages ? <b>Ja</b> : 'Nej',
    }),
    {
      key: 'Auto-godkänn',
      value: autoApprove ? <b>Ja</b> : 'Nej',
    },

    {
      key: 'Från',
      value: (
        <FormattedAddress
          address={fromAddress}
          city={fromCity}
          county={fromCounty?.areaName}
          name={fromName}
          zipCode={fromZip}
        />
      ),
    },
    {
      key: 'Till',
      value: (
        <FormattedAddress
          address={toAddress}
          city={toCity}
          county={toCounty?.areaName}
          name={toName}
          zipCode={toZip}
        />
      ),
    },
    {
      key: 'Fälttestare',
      value: assignedTo ? (
        <span>
          {assignedTo?.name}{' '}
          {assignedTo && (
            <CaseAssignmentAvailabilityWidget
              user={assignedTo}
              date={estimatedStartTime ?? bookedTo}
            />
          )}
          {(assignment.assigneeIsUnavailableOnDate ||
            (drivingPolicyReportResult.data &&
              isViolatingDrivingPolicy(drivingPolicyReportResult.data))) && (
            <TooltipInfoWarning
              isUnavailableViolation={assignment.assigneeIsUnavailableOnDate}
              warnings={drivingPolicyReportResult.data?.warnings}
              user={assignedTo}
              showOnlyTooltip
            />
          )}
          {assignedTo.userID && assignmentID >= 0 && (
            <div style={{ gap: 5, display: 'inline-flex' }}>
              <WorkHoursSpan
                drivinPolicyReport={drivingPolicyReportResult.data}
              />
              <ReturnAssignmentFinder
                assignmentModel={assignment}
                caseModel={assignmentCase.case}
                drivingPolicyReport={drivingPolicyReportResult.data}
              />
            </div>
          )}
        </span>
      ) : undefined,
    },
    {
      key: 'Estimerad uppdragstid',
      value: estimatedDuration ? (
        <span>
          {getHoursAndMinutesFromMillisecondsString(estimatedDuration)}
          {` (${startAndEndTimeString})`}
        </span>
      ) : sameTimePurchaseWithEstimatedDuration?.assignment
          .estimatedDuration ? (
        <span style={{ opacity: 0.5 }}>
          {getHoursAndMinutesFromMillisecondsString(
            sameTimePurchaseWithEstimatedDuration.assignment.estimatedDuration
          )}
          {` (${sameTimePurchaseStartAndEndTimeString}, samma som ${sameTimePurchaseWithEstimatedDuration.case.registrationNumber})`}
        </span>
      ) : undefined,
    },
    ...arraySpreadIf(
      assignmentCancelTypeID ===
        AssignmentCancelTypeEnum.CanceledNotStartedSameDay,
      {
        key: 'Standby-tid',
        value: (
          <span>
            {getHoursAndMinutesFromMillisecondsString(standByAssignmentTime!)}{' '}
            (används för löneunderlag för avbokade uppdrag samma dag)
          </span>
        ),
      }
    ),
    {
      key: 'Starttid',
      value: startTime ? formatDateTime(startTime) : '-',
    },
    { key: 'Sluttid', value: endTime ? formatDateTime(endTime) : '-' },
    {
      key: 'Uppdragstid',
      value: assignmentTimeDisplay,
    },
    {
      key: 'Matrast',
      value: `${breakTime}min`,
    },
    {
      key: 'Arbetad tid',
      value: workedTimeDisplay,
      disabled: fixedAssignmentTime !== undefined && fixedAssignmentTime > 0,
    },
    {
      key: 'Estimerad tid',
      value: estimatedDuration
        ? getHoursAndMinutesFromMillisecondsString(estimatedDuration)
        : undefined,
    },
  ];
  if (fixedAssignmentTime && fixedAssignmentTime > 0) {
    rows.push({
      key: 'Fast tid',
      value: getHoursAndMinutesFromMillisecondsString(fixedAssignmentTime),
    });
  }

  rows.push({
    key: 'Information från Ride',
    value: extraInfo,
  });

  rows.push({
    key: 'Förväntade utgifter',
    value: expectedExpenses.reduce((acc, cur) => {
      if (acc === '') {
        return `${expenseTypes[Number(cur.type)]}`;
      }
      return `${acc}, ${expenseTypes[Number(cur.type)]}`;
    }, ''),
  });

  // show the standardized comments contents (i.e. not just the title)
  rows.push({
    key: 'Information till fälttestaren',
    value: (
      <p style={{ whiteSpace: 'pre-wrap' }}>
        {assignment.standardizedComments.reduce((acc, cur, index) => {
          if (index === 0) {
            return `${cur.comment}`;
          }

          return `${acc}\n${cur.comment}`;
        }, '' as string)}
      </p>
    ),
  });

  return (
    <Wrapper assignmentStatus={assignmentStatusID}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StyledKVList colonKey rows={rows} />
        <Suspense fallback="Loading...">
          <StandardizedComments
            assignmentId={assignmentID}
            commentsOnAssignment={assignment.standardizedComments}
            onRefreshCase={async () => {
              await caseResponse?.update();
            }}
          />
        </Suspense>
      </div>

      <Actions>
        <AuthorizedTextButton onClick={() => setShowNewExpenseModal(true)}>
          + Ny utgift
        </AuthorizedTextButton>
        <AuthorizedTextButton onClick={handleCopyCaseAssignment}>
          <FontAwesomeIcon icon={faClone} /> Kopiera
        </AuthorizedTextButton>
        {!nonCancelableAssignmentStatuses.has(assignmentStatusID) && (
          <AuthorizedTextButton
            onClick={() => onCancelClick(assignment.assignmentID)}
          >
            <FontAwesomeIcon icon={faCancel} /> Avboka
          </AuthorizedTextButton>
        )}
        {showMarkedAsHandled && (
          <AuthorizedTextButton onClick={() => onMarkAsHandled()}>
            Markera som hanterad
          </AuthorizedTextButton>
        )}
        {!nonCancelableAssignmentStatuses.has(assignmentStatusID) &&
          assignmentStatusID < AssignmentStatusEnum.Started && (
            <AuthorizedTextButton
              onClick={() => onDiscardClick(assignment.assignmentID)}
            >
              <FontAwesomeIcon icon={faTrashAlt} /> Ta bort
            </AuthorizedTextButton>
          )}
        {isAssignmentApprovable(assignmentStatusID, !!endTime) && (
          <AuthorizedTextButton
            disabled={overlaps.length > 0}
            onClick={() => onApproveClick(assignment.assignmentID)}
            title={overlaps.length > 0 ? 'Överlappande uppdrag' : ''}
          >
            Godkänn
          </AuthorizedTextButton>
        )}
        <EllipsisWrapper
          onBlur={() => {
            if (showPredefinedActions) {
              setShowPredefinedActions(false);
            }
          }}
          onFocus={() => {
            if (
              !showPredefinedActions &&
              assignmentStatusID !== AssignmentStatusEnum.Discarded
            ) {
              setShowPredefinedActions(true);
            }
          }}
          onMouseLeave={() => {
            if (showPredefinedActions) {
              setShowPredefinedActions(false);
            }
          }}
          onMouseOver={() => {
            if (
              !showPredefinedActions &&
              assignmentStatusID !== AssignmentStatusEnum.Discarded
            ) {
              setShowPredefinedActions(true);
            }
          }}
        >
          <span style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
            <FontAwesomeIcon icon={faRunning} /> Snabbval
          </span>
          {showPredefinedActions && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                right: 0,
                border: '1px solid black',
                borderRadius: '3px',
                backgroundColor: 'white',
                padding: '6px',
                alignItems: 'flex-start',
              }}
            >
              {includesRoles(me?.roles ?? [], [Roles.Admin]) && assignedTo && (
                <>
                  <h1>Annonsbil</h1>

                  {assignment.assignmentRequests.length === 0 ? (
                    <AuthorizedTextButton
                      disabled={
                        assignmentStatusID > AssignmentStatusEnum.Started
                      }
                      onClick={() => {
                        setPredefinedActionType(
                          PredefinedAction.REQUEST_INTERNAL_TRANSPORT
                        );
                      }}
                    >
                      {
                        predefinedActions[
                          PredefinedAction.REQUEST_INTERNAL_TRANSPORT
                        ]
                      }
                    </AuthorizedTextButton>
                  ) : (
                    <AuthorizedTextButton
                      disabled={
                        assignmentStatusID > AssignmentStatusEnum.Started ||
                        (
                          [
                            AssignmentRequestStatusEnum.Denied,
                            AssignmentRequestStatusEnum.Removed,
                          ] as AssignmentRequestStatusEnum[]
                        ).includes(
                          assignment.assignmentRequests[0]
                            ?.assignmentRequestStatusID
                        )
                      }
                      onClick={() =>
                        setPredefinedActionType(
                          PredefinedAction.CANCEL_INTERNAL_TRANSPORT_REQUEST
                        )
                      }
                    >
                      {
                        predefinedActions[
                          PredefinedAction.CANCEL_INTERNAL_TRANSPORT_REQUEST
                        ]
                      }
                    </AuthorizedTextButton>
                  )}
                </>
              )}
              <h1>Snabbvaladdress</h1>
              {editMode && (
                <AuthorizedTextButton onClick={() => onEditClick(assignment)}>
                  Redigera
                </AuthorizedTextButton>
              )}

              {!nonStopOverableAssignmentStatuses.has(assignmentStatusID) && (
                <AuthorizedTextButton
                  onClick={() => onStopOverClick(assignment.assignmentID)}
                >
                  Mellanlanda
                </AuthorizedTextButton>
              )}

              <AuthorizedTextButton
                onClick={() =>
                  setPredefinedActionType(PredefinedAction.ORDER_TO_KUNGSANGEN)
                }
              >
                {predefinedActions[PredefinedAction.ORDER_TO_KUNGSANGEN]}
              </AuthorizedTextButton>

              <AuthorizedTextButton
                onClick={() =>
                  setPredefinedActionType(PredefinedAction.ORDER_TO_PADEL)
                }
              >
                {predefinedActions[PredefinedAction.ORDER_TO_PADEL]}
              </AuthorizedTextButton>

              <AuthorizedTextButton
                onClick={() =>
                  setPredefinedActionType(PredefinedAction.PARK_ZAKRA_OFFICE)
                }
              >
                {predefinedActions[PredefinedAction.PARK_ZAKRA_OFFICE]}
              </AuthorizedTextButton>
              <AuthorizedTextButton
                onClick={() =>
                  setPredefinedActionType(PredefinedAction.PARK_PADEL)
                }
              >
                {predefinedActions[PredefinedAction.PARK_PADEL]}
              </AuthorizedTextButton>
              <AuthorizedTextButton
                onClick={() =>
                  setPredefinedActionType(PredefinedAction.PARK_RM_LOGISTICS)
                }
              >
                {predefinedActions[PredefinedAction.PARK_RM_LOGISTICS]}
              </AuthorizedTextButton>
              {drivingPolicyAssignmentInNeedOfHandling && (
                <>
                  <h1>Körtidspolicy</h1>
                  <AuthorizedTextButton
                    onClick={() =>
                      setPredefinedActionType(
                        PredefinedAction.REQUEST_OVERRIDE_DRIVING_POLICY
                      )
                    }
                  >
                    {
                      predefinedActions[
                        PredefinedAction.REQUEST_OVERRIDE_DRIVING_POLICY
                      ]
                    }
                  </AuthorizedTextButton>
                </>
              )}
            </div>
          )}
        </EllipsisWrapper>
        {/* {(assignment.followUpAssignment || assignment.followedByAssignment) && (
          utkommenterat tills vi validerat att logiken fungerar
          <>
            <Divider />
            {assignment.followUpAssignment && (
              <RightAlignedTextButton
                onClick={() =>
                  window.open(
                    `/sok/${assignment.followUpAssignment?.caseID}`,
                    assignment.followUpAssignment?.caseID.toString() ?? '_blank'
                  )
                }
                title={`Följs av uppdrag ${
                  assignment.followUpAssignment.assignmentID
                } (${
                  assignment.followUpAssignment.fromName ??
                  assignment.followUpAssignment.fromCity
                } - ${
                  assignment.followUpAssignment.toName ??
                  assignment.followUpAssignment.toCity
                })`}
              >
                Följs av <FontAwesomeIcon icon={faExternalLink} />
              </RightAlignedTextButton>
            )}
            {assignment.followedByAssignment && (
              <RightAlignedTextButton
                onClick={() =>
                  window.open(
                    `/sok/${assignment.followedByAssignment?.caseID}`,
                    assignment.followedByAssignment?.caseID.toString() ??
                      '_blank'
                  )
                }
                title={`Föregås av uppdrag ${
                  assignment.followedByAssignment.assignmentID
                } (${
                  assignment.followedByAssignment.fromName ??
                  assignment.followedByAssignment.fromCity
                } - ${
                  assignment.followedByAssignment.toName ??
                  assignment.followedByAssignment.toCity
                })`}
              >
                Föregås av <FontAwesomeIcon icon={faExternalLink} />
              </RightAlignedTextButton>
            )}
          </>
        )} */}
      </Actions>

      {showNewExpenseModal && (
        <NewExpenseModal
          assignmentId={assignment.assignmentID}
          onCancel={() => setShowNewExpenseModal(false)}
        />
      )}

      {predefinedActionType !== undefined && (
        <PredefinedActionModal
          assignment={assignment}
          caseType={currentCaseType}
          onClose={() => setPredefinedActionType(undefined)}
          actionType={predefinedActionType}
        />
      )}
    </Wrapper>
  );
};

export default CaseAssignment;
